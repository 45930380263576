<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!small"
        small
        icon
        v-bind="attrs"
        v-on="on"
        :class="classStr"
      >
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-btn v-else x-small icon v-bind="attrs" v-on="on" :class="classStr">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="text-h5 grey lighten-2"
        style="
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
        "
      >
        <div v-if="!title">
          {{
            subsection !== ''
              ? $t(
                  'documentation.titles.' +
                    section +
                    '.subsections.' +
                    subsection
                )
              : $t('documentation.titles.' + section + '.title')
          }}
          <span v-if="subsection !== ''" class="pl-1 subtitle-2">
            {{ $t('documentation.titles.' + section + '.title') }}
          </span>
        </div>
        <div v-else>
          {{ title }}
        </div>
        <v-btn fab @click="dialog = false" small plain>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-container fill-height fluid>
        <v-row v-if="subsection === ''" align="center" justify="center">
          <v-col>
            {{ text !== '' ? text : $t('documentation.shortTexts.' + section) }}
          </v-col>
        </v-row>
        <v-row v-else align="center" justify="center">
          <v-col>
            {{ $t('documentation.shortTexts.' + section + '.' + subsection) }}
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions v-if="hasPage && !hideButton">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="goToLink()">
          {{ $t('moreInfo') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    section: String,
    subsection: {
      default: '',
      type: String,
    },
    classStr: {
      default: '',
      type: String,
    },
    hasPage: {
      default: true,
      type: Boolean,
    },
    text: {
      type: String,
      default: '',
    },
    hideSubtitle: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadAllNotifications: false,
      loadingAll: false,
      loadingData: false,
      dialog: false,
    }
  },
  methods: {
    goToLink() {
      if (this.subsection === '' || this.hideSubtitle) {
        if (!this.$store.state.appWebManual) {
          // modo original tal cual estaba, con la ayuda embebida
          this.$router.push({
            name: 'DocumentationView',
            query: { section: this.section },
          })
        } else {
          // ve directamente a la pagina web de ayuda en una nueva pestaña, sin pasar por varios componentes y sin control de tipo de usuario
          window.open(
            this.$t('userManual.baseURL') +
              this.$t('userManual.titles.' + this.section + '.title'),
            '_blank'
          )
        }
      } else {
        if (!this.$store.state.appWebManual) {
          // modo original tal cual estaba, con la ayuda embebida
          this.$router.push({
            name: 'DocumentationView',
            query: { section: this.section, subsection: this.subsection },
          })
        } else {
          // ve directamente a la pagina web de ayuda en una nueva pestaña, sin pasar por varios componentes y sin control de tipo de usuario
          window.open(
            this.$t('userManual.baseURL') +
              this.$t('userManual.titles.' + this.section + '.title') +
              '/' +
              this.$t(
                'userManual.titles.' +
                  this.section +
                  '.subsections.' +
                  this.subsection
              ),
            '_blank'
          )
        }
      }
    },
  },
}
</script>
