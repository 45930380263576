<template>
  <v-card class="pa-5" elevation="0">
    <vue-pdf-embed v-if="isPdf" :source="pdfsrc" />
    <div v-else-if="isRtf" v-html="rtfHtml"></div>
    <div v-else>
      <p v-html="$t('nonPdfViewerNotice')" class="styled-text"></p>
    </div>
  </v-card>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { EMFJS, RTFJS, WMFJS } from 'rtf.js'

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    blob: Blob,
    contentType: {
      type: String,
      required: true,
    },
    rtfString: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      rtfHtml: '',
    }
  },
  computed: {
    pdfsrc() {
      return URL.createObjectURL(this.blob)
    },
    isPdf() {
      return this.contentType === 'application/pdf'
    },
    isRtf() {
      return (
        this.contentType === 'text/richtext' ||
        this.contentType === 'application/rtf'
      )
    },
  },
  methods: {
    async processRtf() {
      if (!this.isRtf || !this.rtfString) return ''
      try {
        RTFJS.loggingEnabled(false)
        WMFJS.loggingEnabled(false)
        EMFJS.loggingEnabled(false)

        const doc = new RTFJS.Document(this.stringToArrayBuffer(this.rtfString))
        // Por el momento creo que los metadatos no nos interesan
        //const meta = doc.metadata()
        //console.log('Meta: ', meta)

        // Renderizar el contenido
        const htmlElements = await doc.render()

        // Combinar todos los divs del array en un solo string HTML
        const combinedHtml = htmlElements.map((div) => div.outerHTML).join('')

        // Asignar a la variable que se carga en v-html
        this.rtfHtml = combinedHtml
      } catch (error) {
        //console.log('Error procesando el archivo RTF: ', error)
        this.rtfHtml = <p>this.$t('nonPdfViewerNotice')</p>
      }
    },
    stringToArrayBuffer(string) {
      const buffer = new ArrayBuffer(string.length)
      const bufferView = new Uint8Array(buffer)
      for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i)
      }
      return buffer
    },
  },
  watch: {
    rtfString: 'processRtf',
  },
}
</script>
<style lang="scss" scoped>
.styled-text {
  font-weight: bold;
  /*font-size: 1.1em;*/ /* Tamaño de fuente mas grande */
}
</style>
