const mutations = {
  setLoading(state, value) {
    state.loading = value
  },
  setNotFound(state, notFound) {
    state.notFound = notFound
  },
  setNavigationDrawer(state, navigationDrawer) {
    state.navigationDrawer = navigationDrawer
  },
  setSnackbar(
    state,
    { isDisplayed = true, position, message, type, timeout = 5000 }
  ) {
    const snackbar = { isDisplayed, position, message, type, timeout }
    state.snackbar = snackbar
  },
  setDialog(
    state,
    { isDisplayed = true, maxWidth = '350', title, message, error }
  ) {
    const dialog = { isDisplayed, maxWidth, title, message, error }
    state.dialog = dialog
  },
  setProgress(
    state,
    {
      isDisplayed = true,
      height = 20,
      color = 'progress',
      movement = true,
      message,
      link,
      messageParams,
    }
  ) {
    const newItem = {
      isDisplayed,
      message,
      height,
      color,
      movement,
      link,
      messageParams,
    }
    if (
      newItem.message === 'updatingNotificationsText' &&
      newItem.isDisplayed === false
    ) {
      // si existe, buscar y eliminar el mensaje ya existente en el array
      const index = state.progress.findIndex((item) => {
        return (
          item.message === 'updatingNotificationsText' &&
          item.isDisplayed === true
        )
      })
      if (index !== -1) {
        state.progress.splice(index, 1)
      }
    } else {
      state.progress.push(newItem)
    }
  },
  setBrand(state, brand) {
    state.brand = brand
  },
  setFilters(state, filters) {
    state.filters = filters
  },
  setManageAutomatedTasks(state, automatedTasks) {
    state.manageAutomatedTasks = automatedTasks
  },
  setManageAutomatedTasksUnsubscribe(state, method) {
    state.manageAutomatedTasksUnsubscribe = method
  },
  setManageCompanies(state, operators) {
    state.manageCompanies = operators
  },
  setManageCompaniesUnsubscribe(state, method) {
    state.manageCompaniesUnsubscribe = method
  },
  setManageOperators(state, operators) {
    state.manageOperators = operators
  },
  setManageOperatorsUnsubscribe(state, method) {
    state.manageOperatorsUnsubscribe = method
  },
  setUser(state, user) {
    state.user = user
  },
  setPlan(state, plan) {
    state.plan = plan
  },
  setPlanUnsubscribe(state, method) {
    state.planUnsubscribe = method
  },
  setNews(state, news) {
    state.news = news
  },
  setNewsUnsubscribe(state, method) {
    state.newsUnsubscribe = method
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setNotificationsUnsubscribe(state, method) {
    state.notificationsUnsubscribe = method
  },
  setSignatures(state, signatures) {
    state.signatures = signatures
  },
  setSignaturesUnsubscribe(state, method) {
    state.signaturesUnsubscribe = method
  },
  setNotificationsListOptions(state, options) {
    state.notificationsListOptions = options
  },
  setNotificationsSearch(state, search) {
    state.notificationsSearch = search
  },
  setSignaturesListOptions(state, search) {
    state.signaturesListOptions = search
  },
  setSignaturesSearch(state, search) {
    state.signaturesSearch = search
  },
  setNavigationPages(state, navigationPages) {
    state.navigationPages = navigationPages
  },
  setRemoteAppVersion(state, remoteAppVersion) {
    state.remoteAppVersion = remoteAppVersion
  },
  setMaintenanceMode(state, maintenanceMode) {
    state.maintenanceMode = maintenanceMode
  },
  setContractModel(state, contractModel) {
    state.contractModel = contractModel
  },
  setAppBilling(state, billing) {
    state.billing = billing
  },
  setPending(state, pending) {
    state.pending = { ...state.pending, ...pending }
  },
  setAppWebManual(state, appWebManual) {
    state.appWebManual = appWebManual || false
  },
  addListener(state, listener) {
    state.arrayListener.push(listener)
  },
  killListenersMutation(state) {
    // Matamos los listener del usuario
    if (state.newsUnsubscribe) state.newsUnsubscribe()
    if (state.notificationsUnsubscribe) state.notificationsUnsubscribe()
    if (state.signaturesUnsubscribe) state.signaturesUnsubscribe()
    if (state.planUnsubscribe) state.planUnsubscribe()

    // Matamos los listener creados para el apartado Gestión
    if (state.manageAutomatedTasksUnsubscribe)
      state.manageAutomatedTasksUnsubscribe()
    if (state.manageCompaniesUnsubscribe) state.manageCompaniesUnsubscribe()
    if (state.manageOperatorsUnsubscribe) state.manageOperatorsUnsubscribe()

    for (let i = 0; i < state.arrayListener.length; i++) {
      const listener = state.arrayListener[i]
      listener()
    }
  },
  resetState(state) {
    state.notFound = false
    state.navigationDrawer = false
    state.snackbar = {}
    state.dialog = {}
    state.progress = []
    //state.brand = null - NO SE MODIFICA PARA PRESERVAR SI NO SE DA F5
    state.filters = null
    state.manageAutomatedTasks = null
    state.manageAutomatedTasksUnsubscribe = () => {}
    state.manageCompanies = null
    state.manageCompaniesUnsubscribe = () => {}
    state.manageOperators = null
    state.manageOperatorsUnsubscribe = () => {}
    state.user = null
    state.plan = null
    state.planUnsubscribe = () => {}
    state.loading = false
    //state.maintenanceMode = false - NO SE MODIFICA PARA PRESERVAR TRAS LOGOUT FORZADO
    state.navigationPages = {}
    state.notifications = []
    state.notificationsUnsubscribe = () => {}
    state.signatures = []
    state.signaturesUnsubscribe = () => {}
    state.news = []
    state.newsUnsubscribe = () => {}
    state.notificationsListOptions = {}
    state.notificationsSearch = ''
    state.signaturesListOptions = {}
    state.signaturesSearch = ''
    state.localAppVersion = process.env.VUE_APP_VERSION
    state.remoteAppVersion = ''
    state.contractModel = ''
    state.arrayListener = []
    state.billing = ''
    state.appWebManual = false
  },
  resetProgress(state) {
    state.progress = []
  },
}

export default mutations
